.left-panel-container {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(64, 64, 64, 0.1), rgb(0, 0, 0) 80%),
    url("./tree-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
  position: relative;

  .text-on-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
}

.right-panel-container {
  min-width: 508px;
  background-color: white;
  padding: 40px;
}
